import { usePermissions, useTranslate } from "../../../../customHooks";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {useHistory} from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "../../../../components";
import { FormLayout, RadioGroup, Select, SubmitButton } from "@panwds/react-form";
import { useIntl } from "react-intl";
import { Banner, Button, Link, LoadingPanel, Tooltip } from "@panwds/react-ui";
import {
    useGetRuleStacksQuery,
    useGetLinksQuery,
    useAssociateLinkMutation,
    useDisassociateLinkMutation,
    useAssociateRuleStackMutation,
    useDisassociateRuleStackMutation
} from "../../../../redux/services/policies-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { isEmpty } from "lodash";
import { RouteUri } from "../../../../routeUri";
import { InfoIcon } from '@panwds/icons';
import { determinePolicyManaged } from "../../firewallsUtil";
import { FirewallUpdateBanner } from "../../FirewallUpdateBanner";
import { firewallStyles } from "../../style";

const FirewallSecurityPolicies = (props: { firewallData: Record<string, any>, isLoading: boolean, refreshFirewallData: () => void }) => {
    const translate = useTranslate();
    const search = useLocation().search;
    const intl = useIntl();
    const history = useHistory();
    const { permissions } = usePermissions();
    const accountId = new URLSearchParams(search).get('AccountId');
    const region = useMemo(() => new URLSearchParams(location.search).get('region'), [location.search]) || '';
    const [associateLink] = useAssociateLinkMutation();
    const [disassociateLink] = useDisassociateLinkMutation();
    const [associateRuleStack] = useAssociateRuleStackMutation();
    const [disassociateRuleStack] = useDisassociateRuleStackMutation();
    const firewallUpdating = (["CREATING", "UPDATING", "CREATE_FAIL", "DELETING"].includes(props?.firewallData?.Status?.FirewallStatus) || ["Updating"].includes(props?.firewallData?.Firewall?.LinkStatus));
    const classes = firewallStyles();

    // Fetch RuleStacks
    const { data: ruleStacks = [], error: ruleStacksError } = useGetRuleStacksQuery({
        Region: region, Scope: "Local", Running: true, Candidate: false
    });

    // Fetch Links
    const { data: links = [], error: linksError } = useGetLinksQuery({ accountId });

    const { cloudManagers, panoramas } = useMemo(() => {
        const strataLinks: any = [];
        const panoramaLinks: any = [];

        if (linksError) {
            toast.error(`Failed to fetch links: ${linksError?.message}`, { toastId: "update-firewall-security-policies" });
        } else if (links) {
            Object.keys(links).forEach(linkId => {
                const link = links[linkId];
                const value = link.LinkName ? `${linkId} (${link.LinkName})` : linkId;
                if (value !== undefined && link.Status === 'Active') {
                    if (linkId.includes('Link-SCM')) {
                        strataLinks.push({ text: value, value: linkId });
                    } else {
                        panoramaLinks.push({ text: value, value: linkId });
                    }
                }
            });
        }
          // Add "None" option if the array is not empty and conditions are met
        if (strataLinks.length > 0 && props.firewallData?.Firewall?.LinkId?.includes('Link-SCM')) {
            strataLinks.unshift({ text: "None", value: "None" });
        }
        if (panoramaLinks.length > 0 && props.firewallData?.Firewall?.LinkId?.includes('Link-') && !props.firewallData?.Firewall?.LinkId?.includes('Link-SCM')) {
            panoramaLinks.unshift({ text: "None", value: "None" });
        }

        return { cloudManagers: strataLinks, panoramas: panoramaLinks };
    }, [links, linksError]);

    // Transform RuleStack choices
    const ruleStackChoices = useMemo(() => {
        if (ruleStacks?.length === 0) {
            return [{ label: "Default Rulestack", value: "Default Rulestack" }];
        }

    const choices = ruleStacks?.map((rulestack: any) => ({ text: rulestack, value: rulestack }));
    const defaultChoices = [
        { label: "Default Rulestack", value: "Default Rulestack" },
    ];

    return [
        ...defaultChoices,
        ...choices
    ];
    }, [ruleStacks]);

    if (ruleStacksError) {
        toast.error(ruleStacksError?.error, { toastId: "update-firewall-security-policies" });
        //toast.error(`Failed to fetch rule stacks: ${ruleStacksError.message}`, { toastId: "update-firewall-security-policies" });
    };

    const config = {
        Firewall: ['RuleStackName', 'LinkId', 'GlobalRuleStackName', 'UpdateToken', 'FirewallId'],
    };

    const transformFormDefaultValue = useMemo(() => {
        if (isEmpty(props?.firewallData)) {
            return undefined;
        }
        const newStateWithConfig: any = extractFieldsWithConfig(props?.firewallData, config);
        const obj = {
            PolicyManaged: determinePolicyManaged(props?.firewallData),
            Firewall: {
                ...newStateWithConfig.Firewall,
                RuleStackName: newStateWithConfig?.Firewall?.RuleStackName || "Default Rulestack",
                LinkId: newStateWithConfig?.Firewall?.LinkId || "",
                GlobalRuleStackName: newStateWithConfig?.Firewall?.GlobalRuleStackName || "",
            }
        };
        return obj;
    }, [props.firewallData]);

    const formMethods = useForm({ defaultValues: transformFormDefaultValue });

    const { watch, formState: { dirtyFields }, reset, control } = formMethods;
    const policyManagedValue = watch("PolicyManaged");
    const [prevPolicyManagedValue, setPrevPolicyManagedValue] = useState(policyManagedValue);

    useEffect(() => {
        if (policyManagedValue !== prevPolicyManagedValue) {
            if (policyManagedValue === "Panorama" || policyManagedValue === "Strata") {
                formMethods.setValue("Firewall.LinkId", "");
            } else if (policyManagedValue === "Rulestack") {
                formMethods.setValue("Firewall.RuleStackName", "Default Rulestack");
            }
            setPrevPolicyManagedValue(policyManagedValue);
        }
    }, [policyManagedValue, prevPolicyManagedValue, formMethods]);

    const handleDisassociation = useCallback(async (data: Record<string, any>) => {
        const { Firewall } = data;
        const originalLinkId = props.firewallData?.Firewall?.LinkId; // Fetch the original LinkId directly from firewallData
        let response;
        if (Firewall.LinkId === "None") {
            response = await disassociateLink({
                region: region,
                firewall_id: Firewall.FirewallId,
                link_request: {
                    LinkId: originalLinkId, // Use the original LinkId
                    UpdateToken: Firewall.UpdateToken,
                    FirewallId: Firewall.FirewallId
                }
            }).unwrap();
        } else if (Firewall.RuleStackName === "Default Rulestack") {
            response = await disassociateRuleStack({
                region: region,
                firewall_id: Firewall.FirewallId,
                rulestack_request: {
                    UpdateToken: Firewall.UpdateToken,
                    FirewallId: Firewall.FirewallId
                }
            }).unwrap();
        }
        if (response?.ResponseStatus?.ErrorCode !== 0) {
            throw new Error('Failed to disassociate');
        }
        return response;
    }, [disassociateLink, disassociateRuleStack, props.firewallData]);

    const handleAssociation = useCallback(async (data: Record<string, any>) => {
        const { PolicyManaged, Firewall } = data;
        let response;
        if (PolicyManaged === "Panorama" || PolicyManaged === "Strata") {
            response = await associateLink({
                region: region,
                firewall_id: Firewall.FirewallId,
                link_request: {
                    LinkId: Firewall.LinkId,
                    UpdateToken: Firewall.UpdateToken,
                    FirewallId: Firewall.FirewallId
                }
            }).unwrap();
        } else if (PolicyManaged === "Rulestack") {
            response = await associateRuleStack({
                region: region,
                firewall_id: Firewall.FirewallId,
                rulestack_request: {
                    RuleStackName: Firewall.RuleStackName,
                    UpdateToken: Firewall.UpdateToken,
                    FirewallId: Firewall.FirewallId
                }
            }).unwrap();
        }
        if (response?.ResponseStatus?.ErrorCode !== 0) {
            throw new Error('Failed to associate');
        }
        return response;
    }, [associateLink, associateRuleStack, props.firewallData]);

    const onSubmit = useCallback(async (data) => {
        try {
            const { PolicyManaged, Firewall } = data;
            let response;
            // Handle disassociation if "None" is selected
            if ((Firewall.LinkId === "None" && PolicyManaged !== "Rulestack") || (Firewall.RuleStackName === "Default Rulestack" && PolicyManaged !== "Strata" && PolicyManaged !== "Panorama")) {
                response = await handleDisassociation(data);
                // Fetch the latest firewall data
                if (response?.ResponseStatus?.ErrorCode === 0) {
                    props.refreshFirewallData();
                    if (Firewall.LinkId === "None") {
                        reset({
                            ...transformFormDefaultValue,
                            PolicyManaged,
                            Firewall: {
                                ...Firewall,
                                LinkId: "None",
                                UpdateToken: response.Response.UpdateToken
                            }
                        });
                    } else if (Firewall.RuleStackName === "Default Rulestack") {
                        reset({
                            ...transformFormDefaultValue,
                            PolicyManaged,
                            Firewall: {
                                ...Firewall,
                                RuleStackName: "Default Rulestack",
                                UpdateToken: response.Response.UpdateToken
                            }
                        });
                    }
                } else {
                    throw new Error('Failed to disassociate');
                }
            } else {
                // Handle association for new selection
                response = await handleAssociation(data);
                // Fetch the latest firewall data
                await props.refreshFirewallData();
            }

            toast.success(`Firewall ${Firewall?.FirewallId} updated successfully`, { toastId: "update-firewall-security-policies" });
        } catch (error: any) {
            if(error?.code === 409) {
                await props.refreshFirewallData();
            }
            toast.error(`${error?.error}`, { toastId: "update-firewall-security-policies" });
        }
    }, [handleDisassociation, handleAssociation, disassociateLink, disassociateRuleStack, props.firewallData, reset, transformFormDefaultValue, props.refreshFirewallData]);

    const handleCancel = () => {
        reset(transformFormDefaultValue);
    };

    // Define options outside of the render function
    const policyManagedOptions = [
        {
            value: "Rulestack",
            children: (
                <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                    <span>{translate(`resources.firewallsV2.localRulestack`)}</span>
                    <Tooltip label={translate(`resources.firewallsV2.policyManaged.rulestackTooltip`)}>
                        <span><InfoIcon className="tw-text-disabled" /></span>
                    </Tooltip>
                </div>
            )
        },
        {
            value: "Strata",
            children: (
                <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                    <span>{translate(`resources.firewallsV2.strataCloudManager`)}</span>
                    <Tooltip label={translate(`resources.firewallsV2.policyManaged.strataTooltip`)}>
                        <span><InfoIcon className="tw-text-disabled" /></span>
                    </Tooltip>
                </div>
            ),
            // disabled: cloudManagers.length === 0
        },
        {
            value: "Panorama",
            children: (
                <div className="tw-text-secondary tw-space-x-1 tw-flex tw-items-center">
                    <span>{translate(`resources.firewallsV2.panorama`)}</span>
                    <Tooltip label={translate(`resources.firewallsV2.policyManaged.panoramaTooltip`)}>
                        <span><InfoIcon className="tw-text-disabled" /></span>
                    </Tooltip>
                </div>
            ),
            // disabled: panoramas.length === 0
        }
    ];

    const renderPolicyManagedBanner = () => {
        if (policyManagedValue === "Rulestack" && props?.firewallData?.Firewall?.RuleStackName) {
            return (
                <Banner type="inline" appearance="standard" showIcon>
                    {translate('resources.firewallsV2.policyManaged.localRulestackInfo')}
                </Banner>
            );
        } else if (policyManagedValue === "Panorama" && props?.firewallData?.Firewall?.LinkId?.includes('Link-') && !props?.firewallData?.Firewall?.LinkId.includes('Link-SCM')) {
            return (
                <Banner type="inline" appearance="standard" showIcon>
                    {translate('resources.firewallsV2.policyManaged.panoramaInfo')}
                </Banner>
            );
        } else if (policyManagedValue === "Strata" && props?.firewallData?.Firewall?.LinkId?.includes('Link-SCM')) {
            return (
                <Banner type="inline" appearance="standard" showIcon>
                    {translate('resources.firewallsV2.policyManaged.strataInfo')}
                </Banner>
            );
        }
        return null;
    };

    return (
        <div className="tw-relative">
            {props?.isLoading &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            {firewallUpdating &&
                <FirewallUpdateBanner refresh={props.refreshFirewallData} />
            }
            <div className={classes.fwEditHeader}>{translate(`resources.firewallsV2.securityPolicies`)}</div>
            <div className={firewallUpdating || !permissions?.UpdateFirewall ? classes.fwReadOnly : ""}>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <FormLayout>
                            <RadioGroup
                                label={translate('resources.firewallsV2.managedBy')}
                                name="PolicyManaged"
                                horizontal={true}
                                items={policyManagedOptions}
                                dataMetrics={"cloudngfw-firewall-edit-policy-managed"}
                                disabled={props?.firewallData?.Firewall?.LinkId || props?.firewallData?.Firewall?.RuleStackName}
                                register={{ required: !(props?.firewallData?.Firewall?.LinkId || props?.firewallData?.Firewall?.RuleStackName) }} //hack to avoid disabled state save requirement
                            />
                            {renderPolicyManagedBanner()}
                            {policyManagedValue === "Rulestack" && (
                                <Select
                                    name="Firewall.RuleStackName"
                                    placeholder=""
                                    label={translate(`resources.firewallsV2.policyManaged.localRulestack`)}
                                    items={ruleStackChoices?.map((rs: any) => ({
                                        value: rs.value,
                                        children: rs.label,
                                        disabled: props.firewallData?.Firewall?.RuleStackName ? rs.value !== "Default Rulestack" : false
                                    }))}
                                    dataMetrics={"cloudngfw-firewall-edit-rulestack-name"}
                                    disabled={props.firewallData?.Status?.FirewallUpdating}
                                    description={intl.formatMessage(
                                        {
                                            id: "resources.firewallsV2.policyManaged.rulestackDescription",
                                        },
                                        {
                                            ruleStackPage: (
                                                <Link
                                                    dataMetrics="cloudngfw-rulestack-page-link"
                                                    external
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(RouteUri.RuleStackList);
                                                    }}
                                                >
                                                    {translate(`resources.firewallsV2.policyManaged.ruleStackPage`)}
                                                </Link>
                                            ),
                                        }
                                    )}
                                    simpleFormat
                                />
                            )}
                            {policyManagedValue === "Strata" && (
                                <Select
                                    name="Firewall.LinkId"
                                    placeholder=""
                                    label={translate(`resources.firewallsV2.policyManaged.policyManager`)}
                                    items={cloudManagers?.map((cm: any) => ({
                                        value: cm.value,
                                        children: cm.label,
                                        disabled: props.firewallData?.Firewall?.LinkId ? cm.value !== "None" : false
                                    }))}
                                    disabled={cloudManagers.length === 0 || props.firewallData?.Firewall?.GlobalRuleStackName}
                                    dataMetrics={"cloudngfw-firewall-edit-link-id"}
                                    description={intl.formatMessage(
                                        {
                                            id: "resources.firewallsV2.policyManaged.strataDescription",
                                        },
                                        {
                                            integrationPage: (
                                                <Link
                                                    dataMetrics="cloudngfw-scm-page-link"
                                                    external
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(RouteUri.Integrations);
                                                    }}
                                                >
                                                    {translate(`resources.firewallsV2.policyManaged.integrationPage`)}
                                                </Link>
                                            ),
                                        }
                                    )}
                                    simpleFormat
                                />
                            )}
                            {policyManagedValue === "Panorama" && (
                                <Select
                                    name="Firewall.LinkId"
                                    placeholder=""
                                    label={translate(`resources.firewallsV2.policyManaged.linkedPanorama`)}
                                    items={panoramas?.map((pn: any) => ({
                                        value: pn.value,
                                        children: pn.label,
                                        disabled: props.firewallData?.Firewall?.LinkId ? pn.value !== "None" : false
                                    }))}
                                    disabled={props.firewallData?.Firewall?.GlobalRuleStackName || panoramas.length === 0}
                                    dataMetrics={"cloudngfw-firewall-create-link-id"}
                                    description={intl.formatMessage(
                                        {
                                            id: "resources.firewallsV2.policyManaged.panoramaDescription",
                                        },
                                        {
                                            integrationPage: (
                                                <Link
                                                    dataMetrics="cloudngfw-pn-page-link"
                                                    external
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        history.push(RouteUri.Integrations);
                                                    }}
                                                >
                                                    {translate(`resources.firewallsV2.policyManaged.integrationPage`)}
                                                </Link>
                                            ),
                                        }
                                    )}
                                    simpleFormat
                                />
                            )}
                            <div className={classes.formFooterDivider}/>
                            <div className="tw-flex tw-justify-end">
                                <Button onClick={handleCancel} disabled={isEmpty(dirtyFields)} style={{ marginRight: '5px' }}>
                                    {translate(`generic.cancel`)}
                                </Button>
                                <SubmitButton type="submit" disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.save`)}
                                </SubmitButton>
                            </div>
                        </FormLayout>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default FirewallSecurityPolicies;
